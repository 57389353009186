import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar, Alert, Box } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import dompurify from "dompurify";
import DialogAppBar from "../components/DialogAppBar";
import { postRequestUI, putRequestUI } from "common-utils/utils/api";
import { useMutation } from "react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RetailerInsightsForm = ({
  insight,
  retailerId,
  onSave,
  handleClose,
}) => {
  const [insightData, setInsightData] = useState(insight || {});
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const sanitizeHtml = (html) =>
    dompurify.sanitize(html, { USE_PROFILES: { html: true } });

  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "blockquote", "code-block"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const createRetailerInsightMutation = useMutation(createRetailerInsight, {
    onSuccess: () => {
      onSave();
      handleClose();
    },
    onError: (error) => {
      setErrorMessage(error.message);
      setShowError(true);
    },
  });

  const updateRetailerInsightMutation = useMutation(updateRetailerInsight, {
    onSuccess: () => {
      onSave();
      handleClose();
    },
    onError: (error) => {
      setErrorMessage(error.message);
      setShowError(true);
    },
  });

  async function createRetailerInsight(data) {
    return postRequestUI(`retailer/insights/${retailerId}`, data);
  }

  async function updateRetailerInsight(data) {
    return putRequestUI(`retailer/insights/${insightData.id}`, data);
  }

  const validate = () => {
    if (!insightData.title) {
      setErrorMessage("Insight title is required");
      setShowError(true);
      return false;
    }
    if (!insightData.text) {
      setErrorMessage("Insight text is required");
      setShowError(true);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    const data = {
      title: insightData.title,
      text: sanitizeHtml(insightData.text),
      retailerId,
    };

    if (insightData.id) {
      updateRetailerInsightMutation.mutate({ ...data, id: insightData.id });
    } else {
      createRetailerInsightMutation.mutate(data);
    }
  };
  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogAppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Button color="inherit" onClick={handleSubmit}>
            {insightData.id ? "Edit Insight" : "Save Insight"}
          </Button>
        </Toolbar>
      </DialogAppBar>
      <DialogContent>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={() => setShowError(false)}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 4,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            padding: "24px",
          }}
        >
          {/* Quill Editor Section */}
          <Box sx={{ flex: 1, maxWidth: "550px" }}>
            <FormControl fullWidth>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {insightData.id
                  ? "Edit Retailer Insight"
                  : "Create New Retailer Insight"}
              </Typography>
              <TextField
                label="Title"
                variant="standard"
                value={insightData.title || ""}
                onChange={(e) =>
                  setInsightData({
                    ...insightData,
                    title: sanitizeHtml(e.target.value),
                  })
                }
                sx={{ marginBottom: 3 }}
              />
              <ReactQuill
                style={{ height: "300px", width: "100%" }}
                modules={modules}
                value={insightData.text || ""}
                placeholder="Enter insight text"
                onChange={(value) =>
                  setInsightData({ ...insightData, text: sanitizeHtml(value) })
                }
              />
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RetailerInsightsForm;
