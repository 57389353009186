import React, { useState } from "react";
import _ from "lodash";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "react-query";
import { Snackbar, Alert } from "@mui/material";
import { htmlToText } from "html-to-text";

import EditIcon from "@mui/icons-material/Edit";
import { getRequestUI, deleteRequestUI } from "common-utils/utils/api";
import {
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  SharedStyles,
} from "@shared/components/lib/index";

import RetailerInsightsForm from "./RetailerInsightsForm";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = (theme) => ({
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
});

const RetailerInsightsTab = ({ retailerId }) => {
  const [openForm, setOpenForm] = useState(false);
  const [insightData, setInsightData] = useState(null);
  const [deletingInsight, setDeletingInsight] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);

  const { data, refetch: reloadData } = useQuery(
    ["retailer-insights", retailerId],
    async () => {
      return await getRequestUI(`/retailer/insights/${retailerId}`);
    },
  );

  const deleteMutation = useMutation(deleteRetailerInsight, {
    onSuccess: () => reloadData(),
    onError: () => {
      setErrorMessage("Failed to delete insight. Please try again.");
    },
    onSettled: () => setDeletingInsight(null),
  });

  async function deleteRetailerInsight(id) {
    return deleteRequestUI(`retailer/insights/${id}`);
  }

  const handleDelete = (id) => {
    setDeletingInsight(id);
    deleteMutation.mutate(id);
  };

  const openNewInsightForm = () => {
    setInsightData(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setInsightData(null);
  };

  const clickOnEditInsight = (insight) => {
    setInsightData(insight);
    setOpenForm(true);
  };

  const tableData = {
    headers: ["Title", "Text", "", ""],
    body: [
      (insight) => insight.title,
      (insight) => (
        <Tooltip title={htmlToText(insight.text)} placement="top-start">
          <Typography noWrap>
            <span
              style={{
                display: "inline-block",
                width: "50vh",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {htmlToText(insight.text)}
            </span>
          </Typography>
        </Tooltip>
      ),
      (insight) => (
        <Button
          endIcon={<EditIcon />}
          onClick={() => clickOnEditInsight(insight)}
          title="Edit Insight"
        />
      ),
      (insight) =>
        deletingInsight === insight.id ? (
          <CircularProgress size={24} />
        ) : (
          <CIconButton icon="delete" onClick={() => handleDelete(insight.id)} />
        ),
    ],
  };

  return (
    <Grid container sx={styles.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Retailer Insights"} />
        </div>
        <Button
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={openNewInsightForm}
        >
          Add New Insight
        </Button>
        <TableComponent
          headers={tableData.headers}
          rowData={data?.insights || []}
          cellComponents={tableData.body}
        />
      </TableContainer>

      {openForm && (
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          maxWidth="sm"
          fullWidth
        >
          <RetailerInsightsForm
            insight={insightData}
            retailerId={retailerId}
            onSave={reloadData}
            handleClose={handleCloseForm}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default RetailerInsightsTab;
