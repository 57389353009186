import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import TableContainer from "@mui/material/TableContainer";
import {
  IconButton as CIconButton,
  PageTitle,
  SharedStyles,
  TableComponent,
} from "@shared/components/lib/index";
import { getRequestUI } from "common-utils/utils/api";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useQuery } from "react-query";

const RetailerIngestionUrlTab = ({ retailerId }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);

  const retailerIngestionEndpointQuery = useQuery(
    ["retailer-ingestion-endpoints", retailerId],
    getRetailerIngestionEndpoints
  );
  const showProgress = retailerIngestionEndpointQuery.isLoading;

  function getRetailerIngestionEndpoints() {
    return getRequestUI(`/retailer-ingestion-endpoints/${retailerId}`);
  }

  const ingestionEndpointTableData = {
    headers: ["Carrier", "Carrier Name", "Ingestion Endpoint", ""],
    body: [
      (endpoint) => endpoint.carrierApi,
      (endpoint) => endpoint.carrierName,
      (endpoint) => endpoint.url,
      (endpoint) => (
        <CopyToClipboard text={endpoint.url}>
          <CIconButton icon="content_copy" />
        </CopyToClipboard>
      ),
    ],
  };

  const handleCopyEndpoint = async (id) => {
    console.log(`handleCopyEndpoint: ${id}`);
  };

  return (
    <Grid container>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Ingestion Endpoints"} />
        </div>
        <TableComponent
          headers={ingestionEndpointTableData.headers}
          rowData={retailerIngestionEndpointQuery.data || []}
          cellComponents={ingestionEndpointTableData.body}
        />
      </TableContainer>
    </Grid>
  );
};

export default RetailerIngestionUrlTab;
